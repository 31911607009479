<template>
  <StatusSelectPopover
    v-model:open="open"
    :selected-status-id="value?.id"
    :parent-id="boardId"
    :disabled="disabled"
    source="board"
    v-bind="$attrs"
    :trigger-class="triggerClass"
    button-class="px-2 py-1.5 max-h-8"
    @change="handleUpdateTask($event)"
    @update:open="emit('update:open', $event)"
  >
    <div v-if="value" class="flex items-center justify-between gap-2">
      <StatusBadge
        v-if="value"
        class="flex items-center gap-1 text-xs py-0.5 min-h-5 px-1.5 w-fit h-full max-h-5"
        wrapper-class="!ring-0"
        :type="value.type"
        :label="value.name"
        :disabled="disabled"
      />
      <Icon name="i-heroicons-chevron-down-20-solid" class="size-5 text-gray-400 hidden group-hover/cell:block" />
    </div>
    <UButton
      v-else
      variant="ghost"
      color="gray"
      class="font-normal text-gray-400"
      size="2xs"
      :disabled="disabled"
    >
      -
    </UButton>
  </StatusSelectPopover>
</template>

<script lang="ts" setup>
import type { Status } from '#status/types'
import type { TaskFieldRef } from '#field/types'

defineOptions({
  inheritAttrs: false,
})

const props = defineProps({
  taskId: {
    type: String,
    required: true,
  },
  boardId: {
    type: String,
    required: true,
  },
  value: {
    type: [Object, null] as PropType<Status | null>,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  triggerClass: {
    type: Function as PropType<(active: boolean) => string[]>,
  },
})

const emit = defineEmits<{
  (e: 'update:open', value: boolean): void
}>()

const { updateTasks } = useUpdateTask()
const open = ref(false)
const handleUpdateTask = (statusId: string) => {
  updateTasks([props.taskId], { status: statusId }, true)
}

defineExpose<TaskFieldRef>({
  open: () => {
    open.value = true
  },
  close: () => {
    open.value = false
  },
  delete: () => {
    // Do nothing
  },
})
</script>
